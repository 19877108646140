import React, { useState, useEffect, useMemo } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import useApiClient from '../api/apiClient';
import fallback1 from '../assets/images/fallback_lab_images/fallback1.webp';
import fallback2 from '../assets/images/fallback_lab_images/fallback2.webp';
import fallback3 from '../assets/images/fallback_lab_images/fallback3.webp';
import fallback4 from '../assets/images/fallback_lab_images/fallback4.webp';
import fallback5 from '../assets/images/fallback_lab_images/fallback5.webp';
import fallback6 from '../assets/images/fallback_lab_images/fallback6.webp';
import { LabSet } from '../models';

const fallbackImages = [
  fallback1,
  fallback2,
  fallback3,
  fallback4,
  fallback5,
  fallback6,
];

const CatalogPage: React.FC = () => {
  const [labs, setLabs] = useState<LabSet[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const { getLabs } = useApiClient();

  useEffect(() => {
    (async () => {
      const allLabs = await getLabs();
      setLabs(allLabs);
      setLoading(false);
    })();
  }, []); // eslint-disable-line

  // Compute unique tags from all labs
  const allTags = useMemo(() => {
    const tagSet = new Set<string>();
    labs.forEach((lab) => {
      lab.tags.forEach((tag) => tagSet.add(tag));
    });
    return Array.from(tagSet).sort();
  }, [labs]);

  const toggleTag = (tag: string) => {
    setSelectedTags((prev) => {
      if (prev.includes(tag)) {
        return prev.filter((t) => t !== tag);
      } else {
        return [...prev, tag];
      }
    });
  };

  const filteredLabs = labs.filter((lab) => {
    const query = searchQuery.toLowerCase();
    const matchesSearch =
      lab.labSetName.toLowerCase().includes(query) ||
      lab.description.toLowerCase().includes(query) ||
      lab.tags.some((tag) => tag.toLowerCase().includes(query));
    const matchesTags =
      selectedTags.length === 0 ||
      selectedTags.every((t) =>
        lab.tags.map((tag) => tag.toLowerCase()).includes(t.toLowerCase()),
      );

    return matchesSearch && matchesTags;
  });

  const handleDeploy = (labSetId: string) => {
    navigate(`/lab/${labSetId}`);
  };

  const getRandomFallbackImage = (index: number) => {
    return fallbackImages[index % fallbackImages.length];
  };

  if (loading) {
    return (
      <div className="flex flex-col min-h-screen bg-gray-900 p-8">
        <h1 className="text-4xl font-bold mb-4 text-primary-100">Catalog</h1>
        <div className="mb-4 w-1/2">
          <div className="w-full p-2 border border-gray-300 rounded bg-gray-300 animate-pulse"></div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {[...Array(8)].map((_, idx) => (
            <div
              key={idx}
              className="rounded overflow-hidden flex flex-col animate-pulse border border-gray-300"
            >
              <div className="h-40 w-full bg-gray-300"></div>
              <div className="p-4 flex flex-col flex-grow">
                <div className="h-4 bg-gray-300 rounded w-3/4 mb-2"></div>
                <div className="h-3 bg-gray-200 rounded w-full mb-2"></div>
                <div className="h-3 bg-gray-200 rounded w-1/2 mb-2"></div>
                <div className="mt-2 flex flex-wrap gap-1">
                  {[...Array(3)].map((__, tagIdx) => (
                    <div
                      key={tagIdx}
                      className="h-4 bg-gray-200 w-12 rounded"
                    ></div>
                  ))}
                </div>
                <div className="mt-4 h-8 bg-gray-400 rounded w-full"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen bg-gray-900 p-8">
      <h1 className="text-4xl font-bold mb-4 text-primary-100">Lab Catalog</h1>
      <div className="mb-4 flex items-center gap-4">
        <input
          type="text"
          placeholder="Search labs..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-1/4 p-2 border border-blue-300 rounded focus:outline-none focus:border-blue-500 transition-colors placeholder:text-gray-400"
        />
        <div className="flex flex-wrap gap-2">
          {allTags.map((tag) => (
            <button
              key={tag}
              onClick={() => toggleTag(tag)}
              className={`h-10 px-4 text-base flex items-center rounded-full transition-colors ${
                selectedTags.includes(tag)
                  ? 'bg-blue-300 text-blue-900 font-semibold'
                  : 'bg-blue-700 text-white hover:bg-blue-600'
              }`}
            >
              {tag}
            </button>
          ))}
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6 pb-8">
        {filteredLabs.map((lab, idx) => {
          const imageUrl = lab.imageUrl
            ? lab.imageUrl
            : getRandomFallbackImage(idx);
          return (
            <div
              key={lab.labSetId}
              className="rounded overflow-hidden flex flex-col bg-gradient-to-b from-blue-900 via-blue-800 to-slate-800 shadow-xl transform transition-transform duration-500 hover:scale-105 hover:shadow-2xl"
            >
              <img
                src={imageUrl}
                alt={lab.labSetId}
                className="h-40 w-full object-cover"
              />
              <div className="p-4 flex flex-col flex-grow">
                <h2 className="text-xl font-bold text-blue-100 mb-2">
                  {lab.labSetName}
                </h2>
                <p className="text-sm text-blue-100 flex-grow">
                  {lab.description}
                </p>
                <div className="mt-2 flex flex-wrap gap-1">
                  {lab.tags.map((tag) => (
                    <span
                      key={tag}
                      className="text-sm bg-blue-700 text-blue-100 rounded px-2 py-1 mr-1"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
                <button
                  onClick={() => handleDeploy(lab.labSetId)}
                  className="mt-4 bg-blue-700 text-white py-2 px-4 rounded text-center transition-all duration-300 group hover:bg-blue-600"
                >
                  <span className="inline-flex items-center">
                    Deploy
                    <FaArrowRight className="ml-2 transition-transform duration-300 group-hover:translate-x-1" />
                  </span>
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CatalogPage;
