import React from 'react';
import { FaFlask, FaVideo } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';

import Header from '../../../components/Header';

const AdminHeader: React.FC = () => {
  const navLinks = [
    { label: 'Live Labs', icon: <FaFlask />, path: '/admin/live' },
    { label: 'Recordings', icon: <FaVideo />, path: '/admin/recordings' },
  ];

  const adminNav = (
    <>
      {navLinks.map((link) => (
        <NavLink
          key={link.label}
          to={link.path}
          className={({ isActive }) =>
            `flex items-center px-3 py-2 rounded-md text-sm font-medium transition-colors ${
              isActive
                ? 'bg-primary-700 text-white'
                : 'text-white hover:bg-primary-600'
            }`
          }
        >
          <span className="mr-2">{link.icon}</span>
          {link.label}
        </NavLink>
      ))}
    </>
  );

  return <Header navExtensions={adminNav} />;
};

export default AdminHeader;
