import React, { useContext } from 'react';
import { FaBook, FaHome } from 'react-icons/fa';
import { FiLogOut } from 'react-icons/fi';
import { AuthContext } from 'react-oauth2-code-pkce';
import { NavLink } from 'react-router-dom';

import Logo from '../assets/images/logo.svg';
import Title from '../assets/images/site_title.svg';

interface HeaderProps {
  navExtensions?: React.ReactNode;
}

const Header: React.FC<HeaderProps> = ({ navExtensions }) => {
  const auth = useContext(AuthContext);

  const handleLogout = async () => {
    console.log('Logout initiated');

    try {
      localStorage.clear();
      sessionStorage.clear();
      const backendLogoutWindow = window.open(
        'https://auth.dev.mantarange.com/logout',
        '_blank',
      );

      setTimeout(async () => {
        if (backendLogoutWindow) {
          backendLogoutWindow.close();
        }
        if (auth && auth.logOut) {
          await auth.logOut();
        }
      }, 1000);
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const baseNavLinks = [
    { label: 'Catalog', icon: <FaBook />, path: '/catalog' },
    { label: 'Home', icon: <FaHome />, path: '/' },
  ];

  return (
    <header className="flex justify-between items-center px-6 py-3 h-16 bg-primary-800 text-white shadow-md border-b border-primary-700">
      <div className="flex items-center space-x-4">
        <img
          src={Logo}
          alt="logo"
          className="h-10 select-none"
          draggable="false"
        />
        <img
          src={Title}
          alt="title"
          className="h-7 select-none"
          draggable="false"
        />
      </div>
      <div
        className="flex items-center space-x-4"
        data-testid="header-controls"
      >
        <nav className="flex space-x-4">
          {navExtensions}
          {baseNavLinks.map((link) => (
            <NavLink
              key={link.label}
              to={link.path}
              className={({ isActive }) =>
                `flex items-center px-3 py-2 rounded-md text-sm font-medium transition-colors ${
                  isActive
                    ? 'bg-primary-700 text-white'
                    : 'text-white hover:bg-primary-600'
                }`
              }
            >
              <span className="mr-2">{link.icon}</span>
              {link.label}
            </NavLink>
          ))}
        </nav>
        <div className="relative group">
          <button
            onClick={handleLogout}
            className="flex items-center justify-center bg-primary-600 hover:bg-primary-500 text-white p-3 rounded-lg transition-colors duration-200"
            aria-label="Logout"
          >
            <FiLogOut />
          </button>
          <div className="absolute right-1/2 transform translate-x-1/2 -bottom-12 z-50 py-2 px-4 bg-gray-800 text-white text-sm rounded whitespace-nowrap opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200">
            Logout
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
